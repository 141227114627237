<template>
  <div class="payment-methods">
    <div class="payment-methods-headings">
      <div
        :class="['payment-methods-headings-option', { active: paymentOptions === item.value }]"
        v-for="(item, index) in paymentTabs"
        :key="index"
      >
        <h4 @click="selectOption(item)">{{ $t(`${item.name}`) }}</h4>
      </div>
    </div>
    <div class="payment-methods-container" v-if="gateways">
      <component
        :is="paymentOptions"
        :paymentInfo="purchaseDetail"
        :gatewayList="gateways"
        :paymentDetail="purchaseDetail"
        :gatewaydetail="paypalDetail"
        :checkoutDetail="checkoutDetail"
      ></component>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";

export default {
  props: {
    purchaseDetail: {
      type: Object
    },
    gateways: {
      type: Object
    },
    paypalDetail: {
      type: Object
    },
    checkoutDetail: {
      type: Object
    }
  },
  data() {
    return {
      paymentTabs: [],
      activeNetClass: "",
      activeCardClass: "",
      activePaypalClass: "",
      paymentOptions: ""
    };
  },
  created() {
    this.assignOptions();
    // console.log("gateways in payment options", this.gateways);
    // console.log("purchase detail in payment options", this.purchaseDetail);
    // console.log("paypal detail in payment options", this.paypalDetail);
    // console.log("checkout detail in payment options", this.checkoutDetail);
  },
  methods: {
    assignOptions() {
      this.paymentTabs = [
        // { name: "UPI", value: "UPI" },
        // { name: "Cards/Netbanking", value: "cardsNetbanking" },
        // { name: "Wallets", value: "wallets" }
        // { name: 'cards', value: 'cards' }
        // { name: 'NetBanking', value: 'NetBanking'},
        // { name: "Credit/DebitCard", value: "CardPayment" },
        { name: "Credit Card", value: "creditcard" },
        { name: "Paypal", value: "paypal" }
      ];
      this.paymentOptions = "paypal";
    },
    selectOption(option) {
      this.paymentOptions = option.value;
    }
  },
  components: {
    // UPI: () =>
    //   import(/* webpackChunkName: "OrderSummary" */ "./paymentMethods/UPI.vue"),
    // cardsNetbanking: () =>
    //   import(
    //     /* webpackChunkName: "cardsNetbanking" */ "./paymentMethods/cardsNetbanking.vue"
    //   ),
    // wallets: () =>
    //   import(/* webpackChunkName: "wallets" */ "./paymentMethods/wallets.vue"),
    paypal: () =>
      import(
        /* webpackChunkName: "paypal" */ "@/components/gateways/paypal.vue"
      ),
    creditcard: () =>
      import(
        /* webpackChunkName: "cardCheckout" */ "@/components/gateways/cardCheckout.vue"
      )
  }
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
.payment-methods {
  width: 100%;
  font-family: $font-regular;
  background-color: $clr-dark-gd5;
  position: relative;
  &-headings {
    display: flex;
    align-items: center;
    // justify-items: center;
    justify-content: space-evenly;
    background-color: $clr-dark-gd4;
    padding: 1.3em;
    &-option {
      // width: 30%;
      text-align: center;
      padding: 0.7em;
      color: rgba(255, 255, 255, 0.3);
      cursor: pointer;
    }
    .active {
      color: $clr-light-gd2;
      background-color: $clr-dark-gd3;
      border-radius: 7px;
    }
    .payment-methods-headings-option {
      &:hover {
        color: $clr-light-gd2;
      }
    }
  }
  &-container {
    padding: 2rem 3rem;
  }
}

@media only screen and (max-width: 576px) {
  .payment-methods {
    &-headings {
      padding: 1.3em 0.5rem;
      &-option {
        font-size: 13px;
      }
    }
  }
}
</style>
